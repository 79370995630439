* {
  padding: 0;
  margin: 0;
}
.showPage {
  animation: show 0.3s linear forwards 1;
  transition: 0.5s ease-in-out;
}
@keyframes show {
  0% {
    transform: scale(0) translateY(100rem);
  }
  100% {
    transform: scale(1) translateY(0rem);
  }
}

.messageLoader {
  animation: messageLoad 2s infinite;
  transition: 0.5s ease-in-out;
}

@keyframes messageLoad {
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(4.5rem);
  }
}

.chatSection {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.chatSection::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

@keyframes slideinAnimate {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideoutAnimate {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideinAnimate {
  animation: slideinAnimate 0.5s ease-out forwards;
  transition: 2s ease-in-out;
}

.slideoutAnimate {
  animation: slideoutAnimate 0.3s ease-in forwards;
}
