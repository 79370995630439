/* css-side-effects-plugin-ns:node_modules/react-tagsinput/react-tagsinput.css */
.react-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}
.react-tagsinput--focused {
  border-color: #a5d24a;
}
.react-tagsinput-tag {
  background-color: #cde69c;
  border-radius: 2px;
  border: 1px solid #a5d24a;
  color: #638421;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}
.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}
.react-tagsinput-tag a::before {
  content: " \d7";
}
.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 80px;
}

/* css-side-effects-plugin-ns:app/assets/styles/chatBotStyles.css */
* {
  padding: 0;
  margin: 0;
}
.showPage {
  animation: show 0.3s linear forwards 1;
  transition: 0.5s ease-in-out;
}
@keyframes show {
  0% {
    transform: scale(0) translateY(100rem);
  }
  100% {
    transform: scale(1) translateY(0rem);
  }
}
.messageLoader {
  animation: messageLoad 2s infinite;
  transition: 0.5s ease-in-out;
}
@keyframes messageLoad {
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(4.5rem);
  }
}
.chatSection {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.chatSection::-webkit-scrollbar {
  display: none;
}
@keyframes slideinAnimate {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slideoutAnimate {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.slideinAnimate {
  animation: slideinAnimate 0.5s ease-out forwards;
  transition: 2s ease-in-out;
}
.slideoutAnimate {
  animation: slideoutAnimate 0.3s ease-in forwards;
}
/*# sourceMappingURL=/build/v4.0.0/css-bundle-KTPZNQRH.css.map */
